import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from "./App";
import { AuthProvider } from 'react-auth-kit'

const container = document.getElementById('root');
const root = createRoot(container); // 

root.render(
    <AuthProvider
        authType={"cookie"}
        authName={"_auth"}
        cookieDomain={window.location.hostname}
        cookieSecure={false}
    >
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </AuthProvider>
);

