/***********
NavBar
Barra de navegacao
**************/

//Imports
import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavLink, useNavigate } from 'react-router-dom';
import { useSignOut } from 'react-auth-kit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket, faCirclePlus, faSearch } from '@fortawesome/free-solid-svg-icons'

// O componente
export default function NavBar({queryChanger, search, busca, setAdding}) {
    const signOut = useSignOut();
    const navigate = useNavigate();
    const logout = () => {
        signOut();
        navigate("/");
    }
    useEffect(() => {
        queryChanger(busca);
    }, [busca]);
    return (
        <nav className="navbar pt-1 p-0">
            <div className="row w-100">
                <div className="col-3">
                    <NavLink className="navbar-brand ms-1" to="/">
                        <img className="navlogo" src="./logo192.png" />
                        <span className="navsub">Atlas</span>
                    </NavLink>
                </div>
                <div className="col-6">
                    {search ? 
                        <>
                        <input 
                            className="form-control search me-2" 
                            type="search" 
                            placeholder="Pesquisar..." 
                            value={busca}
                            onChange={(e)=> {
                                queryChanger(e.target.value)}
                            } />
                        <FontAwesomeIcon 
                        className="search_icon" icon={faSearch} />
                        </>
                    : <p></p>}

                </div>
                <div className="col-3 text-end p-0">
                    <OverlayTrigger 
                        placement="bottom" 
                        overlay={<Tooltip id='ttadd'>Nova imagem</Tooltip>}>
                        <FontAwesomeIcon className="mx-1 mx-lg-2 cursor add" onClick={() => setAdding(true)} icon={faCirclePlus} inverse/>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="bottom" 
                        overlay={<Tooltip id='ttlogout'>Sair</Tooltip>}>
                        <FontAwesomeIcon className="mx-1 mx-lg-2 cursor logout" onClick={() => logout()} icon={faRightFromBracket} inverse/>
                    </OverlayTrigger>
                </div>
            </div>
        </nav>
        
         
    );
}