/***********
App
Componente principal
**************/

// Import react, router
import React from "react";
import { Routes, Route } from 'react-router-dom';
import { RequireAuth } from 'react-auth-kit'

// Components
import Navbar from "./components/navBar";
import Feed from "./components/feed";
import Login from "./routes/login";
import ErrorPage from "./routes/error-page";
    
// The App
const App = () => { 
    //const [busca, setBusca] = React.useState('');
    const [query, setQuery] = React.useState('');
    const [adding, setAdding] = React.useState(false);
    return (
        <>
            <Routes>                
                <Route path='/' element={
                    <RequireAuth loginPath="/login">
                        <Navbar search queryChanger={setQuery} busca={query} setAdding={setAdding}/>
                        <Feed adding={adding} atualizaBusca={setQuery} feedquery={query} setAdding={setAdding}/>
                    </RequireAuth>
                } />   
                <Route path='/login' element={<Login/>}/>
                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </>
    );
};
 
export default App;


/*         
<Route path='/add_pic' element={
    <RequireAuth loginPath="/login">
        <Navbar queryChanger={setQuery} busca={query}/>
        <Create/>
    </RequireAuth>
} />
*/
