import React, { useEffect, useState } from "react";
import {Image, Transformation} from 'cloudinary-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faPenToSquare, faCalendar} from '@fortawesome/free-regular-svg-icons'
import { faTags, faUser} from '@fortawesome/free-solid-svg-icons'
import { format } from 'date-fns'
import Create from "./create";

function Item({ atualizaBusca, id, del, picid, desc, tags, date, patient }) {
    const tagElements = tags.split(" ").map((tag, index) => (
      <React.Fragment key={index}>
        <button onClick={() => atualizaBusca(tag)} className="tags" href="">{tag}</button>
        {index < tags.length - 1 && " "}
      </React.Fragment>
    ));
  
  return (
      <React.Fragment key={id}>
      <div className="mt-4 col-11 col-sm-5 col-lg-3">
        <div className="card">
      
            <Image className="card-img-top" cloudName="dkxpdeh8w" publicId={picid}>
                <Transformation width="340" height="400" crop="fill" />
      
            </Image>
      
          <div className="card-body">
            <p className="mb-0 card-text">
                <FontAwesomeIcon className="me-1" icon={faUser} />
                <strong>{patient}</strong>
            </p>
            
            <p className="desc_item card-text my-2 ">{desc}</p>
            
            <p className="mb-0 card-text text-muted font-small"><FontAwesomeIcon icon={faCalendar} />  {format(date, 'dd/MM/yyyy')}</p>
          </div>
          <div className="card-footer footer_item">
            <div className="row">
                <div className="col-10">
                    <span className="text-muted font-small">
                        <FontAwesomeIcon className="tags me-2" icon={faTags} />
                        {tagElements}
                    </span>
                </div>
                <div className="col-2 px-1">
                  <FontAwesomeIcon className="icon_add" onClick={() => alert('meh')} icon={faPenToSquare} />
                  <FontAwesomeIcon onClick={() => del(id)} className="icon_add ms-2" icon={faTrashCan} />
                </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}


export default function Feed({atualizaBusca, feedquery, adding, setAdding}) { 
    const [records, setRecords] = useState([]);
    // No inicio, pega itens da DB
      async function getRecords() {
        if (!feedquery){
            const response = await fetch('https://atlas.tbmed.com.br/server/pics');
            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
                return;
            }
            const records = await response.json();
            setRecords(records);
        }else {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("query", feedquery);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            const response = await fetch('https://atlas.tbmed.com.br/server/search/', requestOptions);
            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
                return;
            }
            const records = await response.json();
            setRecords(records);
        }
    }

    useEffect(() => {
        getRecords();
        return;
    }, [feedquery, records.length]);

    // This method will delete a record
    async function deleteRecord(id) {
        await fetch(`https://atlas.tbmed.com.br/server/${id}`, {
        method: "DELETE"
        });
        const newRecords = records.filter((el) => el._id !== id);
        
        setRecords(newRecords);
    }

    // This method will map out the records on the table
    function recordList() {
        if (records.length === 0) {
            return ( 
                <>
                <div className="content">
                    <p className="text-center notfound">Nenhuma imagem encontrada.</p>
                </div>
                </>
            );
          } else {
            return records.map((record) => {
                return (
                    <Item 
                    atualizaBusca={atualizaBusca}
                    id={record._id}
                    del={deleteRecord}
                    picid={record.photoID}
                    tags={record.tags}
                    date={new Date(record.date)}   patient={record.patient}
                    desc= {record.desc}/>
                );
            });
        }
    }

  return (
      <>
          <div className="content">
            <div className="background mx-0 row justify-content-center">
                {adding && <Create setAdding={setAdding} reloadFeed={getRecords}/>}
                {recordList()}
            </div>
          </div>
        </>
    );
}