/***********
Create
Componente com form para adicionar imagens
**************/

// Imports react, router
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { createWorker } from "tesseract.js";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImage} from '@fortawesome/free-regular-svg-icons'
import { faTags, faUser, faCheck , faXmark} from '@fortawesome/free-solid-svg-icons'

const openai_key = process.env.REACT_APP_OPENAI_KEY;

// Variável para arquivo upload
var foto;

// O componente
export default function Create({setAdding, reloadFeed}) {
    const [imageData, setImageData] = useState(null); 
    const [fotoData, setFotoData] = useState(null); 
    const [fotoText, setFotoText] = useState("Enviar foto"); 
    const [laudoText, setLaudoText] = useState("Enviar laudo"); 
    
    const readLaudo = async () => {
        if (!imageData) return;
        try {
            setLaudoText("Processando OCR...");
            const worker = await createWorker();
            await worker.loadLanguage("por");
            await worker.initialize("por");
            const {
                data: { text },
            } = await worker.recognize(imageData);
            
            setLaudoText("Processando GPT...");
            console.log(text);
            const response = await axios.post('https://api.openai.com/v1/chat/completions', {
                "model": "gpt-3.5-turbo",
                 "messages": [{"role": "user", "content": text + "Crie um JSON com somente o nome do paciente (patient_name), Conclusão do exame (desc), tags relacionados à conclusão do exame (tags) e data do exame (date). Formate maiúsculas e minúsculas corretamente."}],
                 "temperature": 0.7,
                max_tokens: 256,
                n: 1
                }, {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + openai_key
                }
            });
            console.log(response.data.choices[0].message.content);
            var parsed = JSON.parse(response.data.choices[0].message.content);
            updateForm({ desc: parsed.desc });
            updateForm({ patient: parsed.patient_name });
            const tagsString = parsed.tags.map(tag => tag.replace(/\s/g, '_')).join(' ');
            updateForm({ tags: tagsString});
            
            const [day, month, year] = parsed.date.split('/');
            const dateObject = new Date(`${year}-${month}-${day}`);
            const isoDateString = dateObject.toISOString().split('T')[0];
            updateForm({ date: isoDateString });
            setLaudoText("Mudar laudo");
        } catch (error) {
            console.error("[OCR]: ", error);
        }
    };
    
    useEffect(() => {
        readLaudo();
    }, [imageData]);

    
    //States
    const [form, setForm] = useState({
        desc: "",
        tags: "",
        patient: "",
        date: "",
    });
 
    // These methods will update the state properties.
    function updateForm(value) {
        return setForm((prev) => {
            return { ...prev, ...value };
        });
    }

    // Salva o nome do arquivo para upload
    function onFileChange(e) {
        foto = e.target.files[0];
        if(!foto)return;
        const reader = new FileReader();
        reader.onloadend = () => {
            const imageDataUri = reader.result;
            setFotoData(imageDataUri);
        };
        
        document.querySelector('.photo_holder').setAttribute('hidden', true);
        reader.readAsDataURL(foto);
        setFotoText("Mudar foto");
    }
    
    // O envio da form
    async function saveData() {
        const formData = new FormData();
        formData.append('file', foto);
        formData.append('desc',form.desc);
        formData.append('tags',form.tags);
        formData.append('patient',form.patient);
        formData.append('date',form.date);
        formData.append('userID',"0");

        // O post
        axios.post("https://atlas.tbmed.com.br/server/add_pic", formData, {
        }).then(res => {
            updateForm({patient: "", desc: "", tags: "", date: ""});
            setAdding(false);
            reloadFeed();
        })
    }
    
  function processLaudo(e) {
    const file = e.target.files[0];
    if(!file)return;
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageDataUri = reader.result;
      console.log({ imageDataUri });
      setImageData(imageDataUri);
    };
    reader.readAsDataURL(file);
      setLaudoText("Mudar laudo");
      
  }
    
    
 // O HTML
 return (
     <div className="mt-4 col-11 col-sm-5 col-lg-3">
        <form>
            <div className="card">
                <div className="card-img-top">
                    <div className="photo_holder">
                    <FontAwesomeIcon className="photo_holder_icon" icon={faFileImage} />
                    </div>
                    <img className="file_img" src={fotoData} alt="" srcSet="" />
                    
                    <div className="row">
                        <div className="col-6 text-center">
                            <input
                                id="foto"
                                name="foto"
                                type="file"
                                onChange={(e) => onFileChange(e)} 
                                accept="image/*"
                                hidden
                            />
                            <label className="file_choose" htmlFor="foto">{fotoText}</label>
                        </div>
                        <div className="col-6 text-center">
                            <input
                                type="file"
                                name="laudo"
                                id="laudo"
                                onChange={processLaudo}
                                accept="image/*"
                                hidden
                            />
                            <label className="file_choose" htmlFor="laudo">{laudoText}</label>
                        </div>
                    </div>
                </div>
              <div className="card-body">
                <div className="row mb-0 card-text">
                    <div className="col-1">
                        <FontAwesomeIcon className="me-1 mt-2 align-middle" icon={faUser} />
                    </div>
                    <div className="col-11">
                        <input 
                            className="form-control"
                            type="text"
                            id="patient"
                            placeholder="Nome do paciente"
                            value={form.patient}
                            onChange={(e) => updateForm({ patient: e.target.value })}
                        />
                    </div>
                </div>

                <p className="desc_item card-text my-2 ">
                    <textarea
                    className="form-control desc_item"
                    id="desc"
                    placeholder="Descrição"
                    value={form.desc}
                    onChange={(e) => updateForm({ desc: e.target.value })}
                    />
                </p>

                <p className="mb-0 card-text text-muted font-small">
                    <input 
                    className="form-control"
                    type="date"
                    id="date"
                    value={form.date}
                    onChange={(e) => updateForm({ date: e.target.value })}
                    />
                </p>
              </div>
              <div className="card-footer footer_item row">
                
                <div className="col-1">
                    <span className="text-muted font-small">
                        <FontAwesomeIcon className="tags me-2" icon={faTags} />

                    </span>
                </div>
                <div className="col-9">

                       <input 
                            className="form-control tag_txt"
                            type="text"
                            id="tags"
                            value={form.tags}
                            onChange={(e) => updateForm({ tags: e.target.value })}
                        />
                </div>
                <div className="col-2 ps-2 pe-0">
                  <FontAwesomeIcon 
                    className="icon_add me-1" 
                    icon={faCheck} 
                    onClick={() => saveData()}
                    />
                  <FontAwesomeIcon 
                    className="icon_add ms-2"  
                    icon={faXmark}
                    onClick={() => setAdding(false)}
                    />
                </div>
              </div>
            </div>
        </form>
      </div>
 );
}